import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import { Element, Link as Ir } from 'react-scroll';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function App() {

  //const [background, setBackground] = useState(false);
  const [scroll, setScroll] = useState(0);
  //const [modal, setModal] = useState(false);
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");
  //const [index, setIndex] = useState(0);
  const [verMenu, setVerMenu] = useState('no_ver_menu');

  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [datos, setDatos] = useState(false);
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [cp, setCP] = useState("");
  const [tipo, setTipo] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      //setBackground(true)
      setScroll(window.scrollY)
    });

    return () => {
      if(window.location.href.substr(0, 5)==='http:' && window.location.href!=='http://localhost:3000/'){
        window.location.href = window.rutas;
      }
      //window.removeEventListener("scroll", (e) => setBackground(false));
    };
  }, [/*background*/]);

  //let suma = 0

  return (
    <div>
      <div className="icono_menu" onClick={() => setVerMenu('ver_menu')}><img src="img/menu.svg" title="Menú" alt="Menú"/></div>
      <div className="cerrar_menu" id={verMenu} onClick={() => setVerMenu('no_ver_menu')}></div>
      <div className="menu" id={scroll>100 && window.screen.width>750 ? 'menu' : window.screen.width>750 ? null : verMenu}>
        <div className="max_width_menu">
          <div className="menu_flex">
            <img src={scroll>100 && window.screen.width>750 ? "img/logo2.svg" : "img/logo.svg"} className="logo_invictus" title={window.nombre} alt={window.nombre}/>
            <div className="enlaces">
              <Ir to="inicio" className="mi_enlace">Inicio</Ir>
              <Ir to="nosotros" className="mi_enlace">Nosotros</Ir>
              <Ir to="servicios" className="mi_enlace">Servicios</Ir>
              <Ir to="conoce" className="mi_enlace">Conoce</Ir>
              <Ir to="contacto" className="mi_enlace">Contacto</Ir>
            </div>
            <div className="redes">
              {
                window._my_redes.map((item, i) => 
                  <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <Element name="inicio"></Element>
      <Carousel fade controls={false} interval={3000} pause={false} indicators={false}>
        {
          window._my_carousel.map((item, i) =>
            <Carousel.Item key={i} className="car_img" style={{backgroundImage: `url(${item.foto})`}}>
              <div className="car_fondo">
                <div>
                  <img src="img/metlifeb.png" title="MetLife" alt="MetLife" className="logo_metlife"/>
                  {item.texto ? <h8>{item.texto}</h8> : null}
                  {item.titulo ? <h9>{item.titulo}</h9> : null}
                  {item.www ? <a href={item.www} className="car_url" target="new">{item.www_decir}</a> : null}
                  {item.ir ? <Ir to={item.ir} className="car_url">{item.ir_decir}</Ir> : null}
                </div>
              </div>
            </Carousel.Item>
          )
        }
      </Carousel>
      <div id="redes">
        <div className="redes">
          {
            window._my_redes.map((item, i) => 
              <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
            )
          }
        </div>
      </div>
      <Element name="nosotros"></Element>
      <div className="fondo_nosotros">
        <div className="max_width padding_width">
          <div className="flex_between alinear">
            <div className="nosotros_texto" data-aos="fade-right">
              <h11>Nosotros</h11>
              <h12>¿Qué es MetLife?</h12>
              <div className="justificar">
                Es una aseguradora a nivel mundial de las número 1, en <b>Metlife</b> nos dedicamos asesorar los asegurados para que cuenten con póliza integre mejores coberturas acuerdo sus necesidades y así alcanzar el máximo beneficio del titular.
                <br/><br/>
                Nuestro principal objetivo es proteger a todo individuo que goce de un seguro vida Met99 y los más ama.
              </div>
              <button className="saber_mas" onClick={() => {
                setModalServicios(true)
                setServicio("saber")
              }}>
                Saber más
              </button>
            </div>
            <div className="nosotros_img" data-aos="fade-left">
              <div className="box_imagen">
                <img src="img/nosotros.jpg" title={window.nombre} alt={window.nombre}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Element name="empresa"></Element>
      <div className="fondo_empresa">
        <div className="max_width padding_width">
          <div className="t_center" data-aos="fade-down">
            <img src="img/mision.svg" className="about_flex" alt="Misión" title="Misión"/>
            <div id="cl"><br/></div>
            <h7>Misión</h7>
            <div id="cl"><br/></div>
            Ser siempre elegidos en elasesoramiento de personas en elrubro de seguros de vida a través dela atención personalizada de nuestroequipo de trabajo, contando con unaexperiencia de más de 12 años en elmercado.
            <br/><br/>
            Brindar la mayor calidad profesional de servicio a nuestros clientes mientras impulsamos el desarrollo profesional de nuestro personal, de acuerdo con las necesidades de la empresa.
          </div>
          <div className="t_center" data-aos="fade-down">
            <img src="img/vision.svg" className="about_flex" alt="Visión" title="Visión"/>
            <div id="cl"><br/></div>
            <h7>Visión</h7>
            <div id="cl"><br/></div>
            Ser una empresa líder en la rama de intermediación de seguros al satisfacer con eficiencia, calidad y servicio las necesidades de nuestros clientes, para así convertirnos en la empresa preferida por ellos.
          </div>
        </div>
      </div>
      {/*
      <Element name="galeria"></Element>
      <div className="galeria" >
        {
          window._my_galeria.map((item, i) =>
            <div key={i} className="my_galeria" onClick={() => {
              setModal(true)
              setIndex(item.id)
            }}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div id="cl"></div>
      */}
      <Element name="servicios"></Element>
      <div className="fondo_servicios">
        <div className="fondo_claro">
          <div className="max_width padding_width">
            <center>
              <h11>Servicios</h11>
              <h14>Tipos de seguro que maneja MetLife</h14>
              <br/>
            </center>
            <div className="texto_servicios">
              MetLife maneja diversos tipos de seguros que se adecuan a tus necesidades, como son:
            </div>
            {
              window._my_servicios.length>0 ?
                <div className="servicios margin_servicios">
                  {
                    window._my_servicios.map((item, i) => 
                      <div className="my_servicio" data-aos="fade-down" key={i}>
                        <div className="box_servicio">
                          <div className="caja_servicio">
                            <img src={item.foto} title={item.titulo} alt={item.titulo}/>
                            <div className="linea_met"/>
                            <div className="servicio_info">
                              <h15>{item.titulo}</h15>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              :
                null
            }
            <div id="cl"></div>
            <div className="texto_servicios">
              Nosotros manejamos el seguro Met99 que es un Seguro de Vida Personalizado que se encarga principalmente de cubrir al titular en caso de fallecimiento.
            </div>
            <center>
              <button className="saber_mas" onClick={() => {
                setModalServicios(true)
                setServicio("seguro")
              }}>
                CONOCE EL SEGURO <b>MET99</b>
              </button>
            </center>
          </div>
        </div>
      </div>
      <Element name="conoce"></Element>
      <div className="fondo_nosotros">
        <div className="max_width padding_width">
          <center>
            <h11>Conoce</h11>
            <h14>Resuelve tus dudas</h14>
          </center>
          <Accordion className="dudas">
            <Accordion.Item eventKey="0">
              <Accordion.Header>¿Qué es un seguro?</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  Un seguro es un contrato entre una persona o empresa y una compañía aseguradora en el cual se establecen las condiciones y términos para la protección económica ante determinados riesgos.
                  <br/><br/>
                  Es un acuerdo que haces con una compañía aseguradora, donde estableces las reglas y condiciones para estar cubiertos frente a situaciones que podrían generar gastos imprevistos.
                  <br/><br/>
                  Al adquirir un seguro, te conviertes en el asegurado y pagas una prima periódica a cambio de que la aseguradora se comprometa a cubrir los gastos y daños especificados en el contrato en caso de que ocurra el evento asegurado.
                  <br/><br/>
                  Al igual hay tipos de seguro: temporal y vitalicio.
                </div>
                <div className="espacio_input"/>
                <center><img src="img/seguro.png" className="img_50" title="Seguro" alt="Seguro"/></center>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>¿A quiénes les brindamos la atención?</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  Los activos y jubilados que más acuden son de las siguientes dependencias: IMSS, ISSSTE, Gobierno del DF y dependencias particulares.
                  <br/><br/>
                  Aunque la mayoría de los asegurados trabajaron o trabajan en dependencias de gobierno, en realidad <b>cualquier persona que quiera tener seguro (sin importar que no forme parte de una dependencia de gobierno) puede adquirirlo con nosotros y gozar de las múltiples coberturas que les otorgamos.</b>
                </div>
                <div className="espacio_input"/>
                <center><img src="img/brindamos.jpg" className="img_40" title="Atención" alt="Atención"/></center>
                <div className="espacio_input"/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Seguro institucional</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  Beneficio otorgado por parte de la dependencia en la cual laboró el jubilado o pensionado,  se pagan 18 meses con base a la pensión que se esté cobrando durante el año en curso en caso de fallecimiento del titular.
                  <br/><br/>
                  Este monto se le paga a los beneficiarios que el mismo asegurado designa.
                </div>
                <div className="espacio_input"/>
                <center><img src="img/seguro_institucional.jpg" className="img_40" title="Seguro institucional" alt="Seguro institucional"/></center>
                <br/>
                <div className="sub_titulo">¿Cómo se paga?</div>
                <div className="at_j tama_text">
                  Se debe contar con la hoja de asignación de beneficiarios que les otorga la subdelegación del ISSSTE cuando se jubilan y deben presentarla en caso de siniestro para certificarla.
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Pago en marcha</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  Es un derecho que tienen los trabajadores del ISSSTE (activos y jubilados) y  prácticamente es un apoyo para gastos funerarios, este puede ser equivalente monetariamente hasta 4 meses de sueldo.
                  <br/><br/>
                  Es requisito indispensable para el cobro del mismo que los beneficiarios presenten el acta de defunción y las facturas para comprobar los gastos generados en el funeral.
                  <br/><br/>
                  Es conocido como un reembolso porque primero lo pagan los beneficiarios y despues lo cobran en la subdelegación del ISSSTE.
                </div>
                <div className="espacio_input"/>
                <center><img src="img/issste.png" className="img_60" title="ISSSTE" alt="ISSSTE"/></center>
                <div className="espacio_input"/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>SINAVID</Accordion.Header>
              <Accordion.Body>
                <div className="sub_titulo">Argumento para generar citas</div>
                <div className="at_j tama_text">
                  <center><b>Sistema Nacional de Vigencia de Derechos</b></center>
                  <br/>
                  El ISSSTE  puso en operación el SINAVID, una Oficina Virtual en la que los derechohabientes pueden obtener su historial de cotización, su historia clínica; o solicitar sus talones de pago, en el caso de que sean pensionados, entre otros servicios.
                  <br/><br/>
                  Es un documento que ayuda como argumento para generar citas debido a que se le dice al asegurado que al contar con este, el ISSSTE se asegura de que estén vigentes y agiliza el proceso de cobro de seguro que realizan los beneficiarios.
                </div>
                <div className="espacio_input"/>
                <center><img src="img/issste.png" className="img_60" title="ISSSTE" alt="ISSSTE"/></center>
                <div className="espacio_input"/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Constancia de vigencia</Accordion.Header>
              <Accordion.Body>
                <div className="sub_titulo">Argumento para generar citas</div>
                <div className="at_j tama_text">
                  Actualización que se hace una vez al año, se realiza para informarle al ISSSTE que están vivos/vigentes, ayuda mucho a los beneficiarios ya que los seguros que tienen por cobrar lo requieren.
                  <br/><br/>
                  Se le entrega a jubilados, pensionados que acuden por el SINAVID.
                  <br/><br/>
                  Documento que ante el ISSSTE va a respaldar el pase de vigencia que el jubilado realizaba (se conocía como Pase de revista o pase de vigencia).
                </div>
                <div className="espacio_input"/>
                <center><img src="img/imss.jpg" className="img_60" title="IMSS" alt="IMSS"/></center>
                <div className="espacio_input"/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>¿Qué son los dividendos?</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  <b>Su nombre correcto es Retiro Parcial de Fondo de Reserva</b>
                  <br/><br/>
                  Beneficio que da la póliza anualmente, NO ES UN INTERÉS, es un rendimiento que brinda la aseguradora el cual depende de 3 variables: la ganancia obtenida por los servicios administrativos, el índice de mortalidad y el porcentaje de rendimiento.
                  <br/><br/>
                  Es la forma de entregarle a los titulares un incentivo por estar pagando su seguro individual.
                  <br/><br/>
                  Al aperturarse es recomendable cobrarlos a partir del segundo año para que se genere un mayor rendimiento.
                </div>
                <div className="caja_acordion">
                  Es un beneficio que otorga la póliza al tener una suma asegurada, este dividendo nunca es la misma cantidad ya que varía en función a las sumas aseguradas o a la capitalización de la póliza.
                  <br/><br/>
                  Se calculan con la tasa de mortalidad (si hay más fallecidos hay menos dividendos), a cómo se paga su póliza, que tan desfasada está su póliza, los movimientos realizados, etc.
                </div>
                <div className="espacio_input"/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>¿Qué es una póliza?</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  Documento oficial que expide la aseguradora bajo las leyes de la Comisión Nacional de Seguros y Fianzas que ampara totalmente a los asegurados para que sea pagable lo que ellos están abonando mensualmente a su póliza.
                </div>
                <div className="caja_acordion">
                  Es la que tienen los titulares y se les entrega al momento de aperturar el seguro, mejor conocida como: Póliza de seguro inicial.
                  <br/><br/>
                  Incluye la antigüedad de la contratación, las coberturas y datos generales del asegurado y sus beneficiarios, y el número asignado por la aseguradora para cada asegurado.
                </div>
                <div className="espacio_input"/>
                <center><img src="img/poliza.jpg" className="img_70" title="Póliza" alt="Póliza"/></center>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>¿Qué es un endoso?</Accordion.Header>
              <Accordion.Body>
                <div className="at_j tama_text">
                  Documento que forma parte de la póliza, que modifica y/o adiciona sus condiciones generales, coberturas o algún elemento contractual.
                </div>
                <div className="caja_acordion">
                  Documento que contiene el último movimiento realizado a la póliza, ya sea un incremento o una inclusión.
                  <br/><br/>
                  Este se debe adjuntar a la Póliza de seguro inicial.
                </div>
                <div id="cl"><br/></div>
                <center><img src="img/endoso.jpg" className="img_70" title="Endoso" alt="Endoso"/></center>
                <div className="espacio_input"/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Element name="contacto"></Element>
      <div className="fondo_contacto">
        <div className="max_width padding_width">
          <center>
            <h11>Contacto</h11>
            <h12>Cuéntanos tu inquietud o realiza una cita</h12>
          </center>
          <form data-aos="fade-down">
            <div className="campos_formulario">
              <input type="text" name="nombre" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre:" value={nombre} required/>
              <input type="text" name="correo" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo:" value={correo} required/>
              <input type="text" name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="Teléfono:" value={telefono} required/>
            </div>
            <textarea name="mensaje" id="area" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje:" value={mensaje} required/>
            <div className="datos"><div className={datos===true ? 'datos_hover' : null} onClick={() => setDatos(!datos)}>+ Realizar un cita</div></div>
            {
              datos===true ?
                <div className="mi_evento">
                  <div className="campos_formulario">
                    <div>
                      Tipo de servicio:
                      <select name="tipo" onChange={(e) => setTipo(e.target.value)}>
                        <option value="" selected={tipo==="" ? true : false}></option>
                        {
                          window._my_tipo.map((item, i) =>
                            <option value={item} key={i} selected={item===tipo ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Fecha:
                      <input type="date" name="fecha" onChange={(e) => setFecha(e.target.value)} placeholder="dd/mm/aaaa" value={fecha}/>
                    </div>
                    <div>
                      Hora:
                      <input type="time" name="hora" onChange={(e) => setHora(e.target.value)} placeholder="00:00" value={hora}/>
                    </div>
                  </div>
                  <div className="campos_formulario">
                    <div>
                      Estado:
                      <select name="estado" onChange={(e) => setEstado(e.target.value)}>
                        <option value="" selected={estado==="" ? true : false}></option>
                        {
                          window._my_estado.map((item, i) =>
                            <option value={item.estado} key={i} selected={item.estado===estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Municipio / Delegación:
                      <select name="municipio" onChange={(e) => setMunicipio(e.target.value)}>
                        <option value="" selected={municipio==="" ? true : false}></option>
                        {
                          window._my_estado.filter(p => p.estado===estado).map(esta =>
                            esta.municipios.map((item, i) =>
                              <option value={item} key={i} selected={item===municipio ? true : false}>{item}</option>
                            )
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Código postal:
                      <input type="number" name="cp" onChange={(e) => setCP(e.target.value)} placeholder="Solo número" value={cp}/>
                    </div>
                  </div>
                </div>
              :
                null
            }
            <center><button onClick={(e) => {
              e.preventDefault();
              if(nombre===''){
                alerta('error','Ingresa tu nombre completo');
              }else if(correo===''){
                alerta('error','Ingresa tu correo electrónico');
              }else if(window.validarMail(correo)===false){
                alerta('error','Ingrese un correo electrónico valido');
              }else if(mensaje===''){
                alerta('error','Escribe un mensaje');
              }else{
                window.H5_loading.show();
                var params = new URLSearchParams();
                params.append('nombre', nombre);
                params.append('correo', correo);
                params.append('mensaje', mensaje);
                params.append('telefono', telefono);

                if(datos===true){
                  params.append('datos', 1);
                }
                params.append('fecha', fecha);
                params.append('hora', hora);
                params.append('estado', estado);
                params.append('municipio', municipio);
                params.append('cp', cp);
                params.append('tipo', tipo);
                
                Axios.post(`${window.envia}`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',response.data.mensaje)
                      setNombre("");
                      setCorreo("");
                      setTelefono("");
                      setMensaje("");

                      setDatos(false)
                      setFecha("");
                      setHora("");
                      setEstado("");
                      setMunicipio("");
                      setCP("");
                      setTipo("");
                    }else{
                      alerta('error',response.data.mensaje)
                    }
                  })
                  .catch(error => {
                    //window.alerta('error','LOLERO'+error);
                    console.log(error)
                  })
                  .then(function() {
                    window.H5_loading.hide();
                  })
              }
            }}>Enviar mensaje</button></center>
          </form>
          <div className="linea_metlife"/>
          <center><h7>Ubicaciones</h7></center>
          <br/>
          <div className="flex_ubicaciones">
            <a data-aos="fade-down" href="https://www.google.com.mx/maps/search/Av.+Baja+California+%23255+Torre+B,+Desp.+202,+Colonia+Hip%C3%B3dromo,+Cuauht%C3%A9moc,+CP.+06100,+CDMX./@19.405915,-99.172967,17z/data=!3m1!4b1?entry=ttu" target="new">
              <img src="img/con_ubi.svg" title="Ubicación" alt="Ubicación"/>
              Av. Baja California #255 Torre B, Desp. 202, Colonia Hipódromo, CP. 06100, Cuauhtémoc, CDMX.
            </a>
            <a data-aos="fade-down" href="https://www.google.com.mx/maps/search/Av.+Insurgentes+Sur+%231677+Desp.+207,+Colonia+Guadalupe+Inn,+%C3%81lvaro+Obreg%C3%B3n,+CP.+01020,+CDMX./@19.36133,-99.1859475,17z/data=!3m1!4b1?entry=ttu" target="new">
              <img src="img/con_ubi.svg" title="Ubicación" alt="Ubicación"/>
              Av. Insurgentes Sur #1677 Desp. 207, Colonia Guadalupe Inn, CP. 01020, Álvaro Obregón, CDMX.
            </a>
            <a data-aos="fade-down" href="https://www.google.com.mx/maps/search/Av.+Plan+de+Ayala+%231298,+Colonia+Chapultepec,+CP.+62450,+Cuernavaca,+Morelos./@18.9215558,-99.2440507,14z/data=!3m1!4b1?entry=ttu" target="new">
              <img src="img/con_ubi.svg" title="Ubicación" alt="Ubicación"/>
              Av. Plan de Ayala #1298, Colonia Chapultepec, CP. 62450, Cuernavaca, Morelos.
            </a>
          </div>
        </div>
      </div>
      <div className="pie">
        <div className="max_width padding_pie">
          <div className="mi_pie alinear">
            <div className="flex_start alinear">
              <Ir to="inicio" className="subir">➤</Ir>
              {window.nombre} © {window.ano}
            </div>
            <div>
              <div className="flex_end alinear">
                <a href={window.whats} target="new" className="flex_tel alinear">
                  <img src="img/con_tel.svg" title="Teléfono" alt="Teléfono"/>
                  {window.telefono}
                </a>
                <a href={`mailto:${window.correo}`} target="new" className="flex_tel alinear">
                  <img src="img/con_mail.svg" title="Teléfono" alt="Teléfono"/>
                  {window.correo}
                </a>
              </div>
              <div className="para_aviso">
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("glosario")
                }}>Glosario</div>
                 | 
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("bolsa")
                }}>Bolsa de trabajo</div>
                 | 
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("aviso")
                }}>Aviso de privacidad</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <a href={window.whats} target="new" className="whats">
        <img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/>
      </a>
      {/*
      <Modal
        show={modal}
        onHide={() => {
          setModal(false)
        }}
        size="lg"
        className="my-modal"
        centered
      >
        <Modal.Body >
          <Carousel className="slider_modal" defaultActiveIndex={index} interval={1500}>
            {
              window._my_galeria.map((item, i) =>
                <Carousel.Item key={i}>
                  <img src={item.foto} alt="Slider"/>
                </Carousel.Item>
              )
            }
          </Carousel>
        </Modal.Body>
      </Modal>
      */}
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Aviso de privacidad</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="at_j">
                  <b>GRUPO INVICTUS, S.A DE C.V.</b> así como sus filiales y subsidiarias en adelante, tienen entre sus objetivos, la convicción de proteger los datos personales sensibles, en su caso, proporcionados por la persona física a quien corresponden dichos datos, en adelante <b>“EL TITULAR”</b>, de lo anterior <b>GRUPO INVICTUS, S.A DE C.V.</b> ha establecido las siguientes disposiciones para proteger dicha información en la medida de los posible, las cuales pueden cambiar en cualquier momento. Por lo que sugerimos consultarlas periódicamente. 
                  <br/><br/>
                  Los términos que no estén expresamente definidos en estas disposiciones, tendrán el significado que les atribuye a los mismos en la <b>LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES</b> en adelante <b>“LA LEY”.</b> 
                  <ul className="lista_avi">
                    <li>
                      INFORMACIÓN PERSONAL SOLICITADA. <b>GRUPO INVICTUS, S.A DE C.V.</b> podrá solicitar información a <b>“EL TITULAR”</b>, con el objeto de poder proporcionarles la mayor experiencia personalizada en el uso de los servicios que se les otorga. El tipo de información que se solicita al titular de los servicios incluye, de manera enunciativa, más no limitativa, el nombre(s), apellido(s), dirección de correo electrónico, teléfono, datos fiscales, dirección de domicilio, entre otros. No toda la información solicitada al usuario al momento de registrarse es obligatoria de proporcionarse, salvo aquella que <b>GRUPO INVICTUS, S.A DE C.V.</b> considere conveniente y que así se le haga saber al usuario al momento del registro, por lo que solicitará dicha información cuando considere sea oportuna o necesaria.
                    </li>
                    <li>
                      <b>“EL TITULAR”</b> debe garantizar que los datos personales que proporciona son correctos, completos, veraces y actuales y que se hace responsable de comunicar a su cliente cualquier modificación y que a su vez este tiene la obligación de notificar a <b>GRUPO INVICTUS, S.A DE C.V.</b> dichas modificaciones.
                    </li>
                    <li>
                      Los términos “Datos Personales” y “Datos Sensibles” tendrán el significado que se les atribuye en el artículo 3° de <b>“LA LEY”.</b>
                    </li>
                    <li>
                      USO, FINALIDAD Y TRATAMIENTO DE LA INFORMACIÓN. <b>GRUPO INVICTUS, S.A DE C.V.</b> utiliza la tecnología más avanzada a su alcance para la protección de la información proporcionada por <b>“EL TITULAR”</b> de los servicios. Esta tecnología encriptado, codifica y previene la intercepción de la información suministrada por el <b>“EL TITULAR”</b>, incluyendo tarjetas de crédito y direcciones de correo electrónico. <b>“EL TITULAR”</b> que facilite sus datos de carácter personal presta su consentimiento expreso para su utilización por <b>GRUPO INVICTUS, S.A DE C.V.</b> con la finalidad y realización de actividades propias de su objeto social. <b>“EL TITULAR”</b> que facilite datos de carácter personal queda informado que dicha comunicación se produce en el mismo momento que proporcione los datos a <b>GRUPO INVICTUS, S.A DE C.V.</b>
                      <br/><br/>
                      TRATAMIENTO. <b>“EL TITULAR”</b> consciente inequívocamente que los datos personales que nos facilite a lo largo de todas las comunicaciones y que sean incorporados a los ficheros que contengan datos de carácter personal, de los que será responsable <b>GRUPO INVICTUS, S.A DE C.V.</b> así como el tratamiento informatizado o no, de los mismos con el fin de que puedan ser utilizados por <b>GRUPO INVICTUS, S.A DE C.V.</b> con una finalidad informativa personalizada o no, estadística, así como actividades propias autorizando a <b>GRUPO INVICTUS, S.A DE C.V.</b> la extracción, almacenamiento de datos al objeto de mejorar nuestra atención en el desarrollo de los servicios prestados e informarle de nuestros actuales y futuros servicios a través de los diferentes medios que se establecen. <b>GRUPO INVICTUS, S.A DE C.V.</b> se reserva el derecho de ceder la información proporcionada por el <b>“EL TITULAR”</b> a cualquiera de sus filiales o subsidiarias, ya sean presentes, o aquella que se constituyan o adquieran en el futuro, a nivel nacional como internacional. De igual modo, por virtud de relación contractual que se establezca entre <b>“EL TITULAR”</b> y <b>“GRUPO INVICTUS, S.A DE C.V.”</b>, esta última podrá proporcionar información personal obtenida a las personas físicas o morales que tengan relación con la implementación de la referida relación contractual. Del mismo modo, <b>“EL TITULAR”</b> manifiesta que está consciente en que <b>GRUPO INVICTUS, S.A DE C.V.</b> podrá transmitir la información proporcionada a cualquier autoridad judicial autoritaria, administrativa o de cualquier índole que en términos de legislación aplicable le sea solicitado.
                    </li>
                    <li>
                      De igual forma, <b>“EL TITULAR”</b> podrá enviar un comunicado a la misma dirección de correo electrónico, cuando su deseo sea el de acceso y rectificación de cualquier dato en la información proporcionada, conforme a lo previsto en <b>“LA LEY”</b>, <b>“EL TITULAR”</b> tendrá en todo momento el derecho a cancelar sus datos personales conforme a lo previsto en <b>“LA LEY”</b>.
                    </li>
                    <li>
                      PROTECCIÓN DE LA INFORMACIÓN <b>GRUPO INVICTUS, S.A DE C.V.</b> expresa su compromiso de proteger la seguridad de la información proporcionada por <b>“EL TITULAR”</b>. Para dichos fines, utilizamos una gran variedad de tecnologías y procedimientos de seguridad, para impedir, en la medida de lo posible, el acceso, uso o divulgación no autorizados. Cuando se transmite la información altamente confidencial (como número de tarjetas bancarias) a través de la red internet, la protegemos mediante encriptación. Será responsabilidad del usuario el que permanezca con carácter confidencial, el uso de cualquier contraseña.
                    </li>
                    <li>
                      CONTACTO. En caso de dudas y/o comentarios en este aviso de privacidad, <b>“EL TITULAR”</b> podrá contactar a <b>GRUPO INVICTUS, S.A DE C.V.</b> mediante la dirección electrónica y al teléfono proporcionado. Para lo anterior, deberá hacernos saber fehacientemente los Datos Personales y /o Datos Sensibles que usted desea sean rectificados o revisados, así como el propósito para el cual los aportó y en general cumplir los requisitos mencionados en el art. 29 de <b>“LA LEY”</b>, obteniendo una respuesta sobre el mismo, en un plazo de 15 días hábiles.
                    </li>
                    <li>
                      MARCO LEGAL VIGENTE. <b>“EL TITULAR”</b> se obliga, a respetar toda la normativa legal vigente relacionada con la utilización, gestión y uso de sistemas de información <b>GRUPO INVICTUS, S.A DE C.V.</b> extiende el presente aviso de privacidad, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales, así como por las normas aplicables a nivel nacional e internacional.
                    </li>
                  </ul>
              </div>
              </Modal.Body>
            </>
          : servicio==='glosario' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Glosario</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion defaultActiveKey="">
                  {
                    window._my_preguntas.map((item, i) =>
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{item.id}. {item.pregunta}</Accordion.Header>
                        <Accordion.Body>
                          <div dangerouslySetInnerHTML={{ __html: item.respuesta }}/>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Modal.Body>
            </>
          : servicio==='saber' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Nuestra historia</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img src="img/historia.jpg" className="img_libro" alt="Historia" title="Historia"/>
                <div className="texto_libro">
                  Desde el año 2012 que fundamos la empresa los valores como la lealtad, la colaboración, la honestidad y el compromiso en otorgar un servicio de calidad han sido los pilares fundamentales para nuestra conformación como equipo de trabajo.
                  <br/><br/>
                  Durante este tiempo hasta el día de hoy hemos crecido de manera paulatina pero con pasos firmes para lograr lo que hoy se ha convertido en una gran familia.
                </div>
              </Modal.Body>
            </>
          : servicio==='bolsa' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Bolsa de trabajo</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  Te invitamos a trabajar en el equipo de MetLife - Grupo Invictus en Ciudad de México y Cuernavaca, nos encantaría crecer contigo.
                </div>
              </Modal.Body>
            </>
          : servicio==='seguro' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Seguro Met99</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="at_j tama_text">
                  Principalmente es el <b>Seguro de Vida Individual</b> que te protege por fallecimiento pero contamos con bastantes coberturas extra*:
                  <ul className="lista_servicios">
                    <li>Coberturas por Cáncer</li>
                    <li>Enfermedades graves</li>
                    <li>Cirugías</li>
                    <li>Apoyo hospitalario</li>
                    <li>Gastos funerarios</li>
                  </ul>
                </div>
                <center><img src="img/met99.jpg" className="img_70" title="Seguro Met99" alt="Seguro Met99"/></center>
                <div className="at_j tama_text">
                  *Pueden quedar cubiertos con estas coberturas desde el asegurado hasta 3 asegurados complementarios extra.
                </div>
              </Modal.Body>
            </>
          :
            null
        }
      </Modal>
    </div>
  );
}

export default App;
